<script lang="ts" setup>
import type { VerticalNavigationLink } from '#ui/types';

// COMPOSABLE
const accountMenuItems = useAccountMenuItems();
const { logout } = useAuthUtils();
const { tt } = useTypedI18n();

// DATA
const sidenavLink = computed<VerticalNavigationLink[][]>(() => [
    accountMenuItems.value,
    [{ label: tt('common.logout'), icon: 'i-mdi-logout', click: logout }],
]);

const verticalNavigationUi = {
    base: 'pb-5',
    padding: 'py-0',
    size: 'text-base',
    active: 'text-primary dark:text-white before:bg-transparent dark:before:bg-gray-800',
    inactive: 'text-black dark:text-gray-400 hover:bg-transparent dark:hover:text-white hover:before:bg-transparent dark:hover:before:bg-gray-800/50',
    label: 'pl-3',
    icon: {
        active: 'text-primary dark:text-gray-200',
        inactive: 'text-black dark:text-gray-500 group-hover:text-black dark:group-hover:text-gray-200',
    },
    divider: {
        wrapper: {
            base: 'pb-5',
        },
    },
};
</script>

<template>
    <BasePage class="lg:grid lg:grid-cols-4">
        <BasePageTitle class="lg:col-span-3 lg:col-start-2" :title="$attrs.title as string" />
        <aside class="lg:scrollbar-hide hidden lg:sticky lg:top-[calc(68px+20px)] lg:mr-6 lg:block lg:max-h-[calc(100vh-68px)] lg:overflow-y-auto">
            <UVerticalNavigation :links="sidenavLink" :ui="verticalNavigationUi" />
        </aside>
        <div class="flex flex-col gap-6 lg:col-span-3">
            <p v-if="$attrs['sub-title']" class="text-base text-black">{{ $attrs['sub-title'] }}</p>
            <BaseVerticalStack :class="$attrs.class">
                <slot />
            </BaseVerticalStack>
        </div>
    </BasePage>
</template>
